import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import LoadingAnimation from 'src/components/LoadingAnimation'
import SEO from 'src/components/seo'
import { breakpoints } from '../../utils/styles/global-styles'

class Typeform extends React.Component {
  render() {
    const { pageContext } = this.props
    if (!pageContext?.formId) return <LoadingAnimation />
    const { formId, title, url } = pageContext

    return (
      <Section>
        <SEO
          title={`${title}`}
          path={`/pages/${url}/`}
          description="Purchase a Skylight Photo Frame to send love effortlessly."
        />
        <iframe
          title={`${title}`}
          id="typeform-full"
          allow="camera; microphone; autoplay; encrypted-media;"
          src={`https://form.typeform.com/to/${formId}?typeform-medium=embed-snippet`}
          width="100%"
          height="100%"
          frameBorder="0"
        />
        <script type="text/javascript" src="https://embed.typeform.com/embed.js" />
      </Section>
    )
  }
}

Typeform.propTypes = {
  pageContext: PropTypes.shape({
    formId: PropTypes.string.isRequired,
    title: PropTypes.string,
    url: PropTypes.string,
  }),
}

export default Typeform

const Section = styled.div`
  height: 100vh;
  background-color: ${(props) => props.theme.cream};
  @media (max-width: ${breakpoints.s}px) {
    padding-bottom: 80px;
  }
`
